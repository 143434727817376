*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  
}

.navbar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
    margin-bottom:1px;
    /* background-color: #e6e6e6; */
    /* border-bottom: 1px solid #f6f6f6; */
    color: white ;

}

.logo{
	font: normal 36px 'Bernard MT Condensed';


}

.nav-link{
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: 75%;

}

.Business,.Solution,.contact,.about,.profile{

    text-decoration: none;
    color:white;
    font-size: 15px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    
}

.Business:hover,.Solution:hover,.contact:hover,.about:hover,.profile:hover{
    color: #e39516;
}

.profile{
    border: 1px solid #f6f6f6;
    border-radius: 5px;
}

.mobile-menu-icon{
    display: none;
}

@media screen and (max-width: 780px) {
    
    .logo{
        display: flex;
        position: absolute;
        top: 15px;
        left: 30px;
    }

    .nav-link{
        display: none;
    }

    .nav-links-mobile{
        position: absolute;
        display: block;
        list-style: none;
        background-color: #0C1222;
        left: 0;
        top: 60px;
        transition: all 0.5s ease-out;
        width:100%;
        z-index:1;

    }



    .Business,.Solution,.contact,.about,.profile{

        color:white;
        text-align: center;
        padding: 32px;
        width: 100%;
        transition: all 0.5s ease;
    }
    .profile{
        border: none;
    }

    .mobile-menu-icon{
        display: block;
        position: absolute;
        font-size: 30px;
        color: white;
        background-color: transparent;
        border: none;
        outline:none;
        top:15px;
        right: 25px;
    }
}