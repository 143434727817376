.bannerimage {
background-image:url('./img/building.jpg');
height:70vh;
width:100%;
background-size: cover;

}


.container {
  
    padding: 64px;
    background:#f6f6f6;
    
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both
  }
  
  .column-66 {
    float: left;
    width: 60%;
    padding-left: 60px;
  }
  
  .column-33 {
    float: left;
    width: 40%;
    padding: 20px;
  }
  
  .large-font {
    font-size: 27px;
   
  }
    
  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  
  @media screen and (max-width: 1000px) {
    .container {
      padding:10px;
      
    }
    .column-66,
    .column-33 {
      width: 100%;
      text-align: center;
      font-size:12px;
      padding:0;
      margin-top:60px;
    }
    img {
      margin: auto;
      
    }
  }