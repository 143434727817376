/* Hero-Section */


.{
  bannerimage_contact:url('./component/img/bg1.jpg');
}

 


  /*---------------------- Carousel --------------------------------------------------------------*/

.carousal{
  display:flex;
  justify-content:center;
}

.carousel .slide{
  height:70vh;
  background:black;
}
.carousel .slide img {
  max-height:100%;
  width:auto;
}

.image{
  margin:100px auto;
  height:100%;
  width:40%;
}

.thumbs-wrapper, .carousel-status{
  display:none;
}


@media only screen and(max-width:1200px){
  .carousel{
    height:70vh;
  }
  .image{    
    margin:100px auto;
    height:100%;
    width:100%;
  }
 
} 

@media only screen and (max-width:900px){
 
  .image{    
    margin:100px auto;
    height:100%;
    width:70%;
  }
  
} 

@media only screen and (max-width:600px){
  .carousel .slide{
    height:60vh;
  }

  .image{    
    margin:100px auto;
    height:100%;
    width:80%;
  }
  
}


@media only screen and (max-width:400px){
  .carousel .slide{
    height:50vh;
  }
  
  .image{    
    margin:50px auto;
    height:100%;
    width:100%;
  }
  
} 

/* -----------------Contuct US----------------------------------- */

