/* card -stlying  */
.card{
    box-shadow: 0 0 9px black;
    width: 350px;
    margin: 100px 20px 20px 20px ;
    align-self: normal;
       
  }
  
  .card-image img{
    width: 100%;
    height: 200px;
    background-color:gray;
  }
  .card_container_title{
    margin-top:10px;
    text-align:center
  }
  .card-title,
  .card-description{
    padding: 10px;
    text-align: left;
  
  }
   
  
  .card-title{
    font-weight: bold;
    font-size: 18px;
     
  }
  .card-description{
    font-size: 16px;
    
  }
  
  .home-cards{
    width: auto;
    text-align: left;
    
  }
  
  .home-cards > .card{
    display: inline-block;
  }
  
  .row{
  
    flex-wrap: wrap;
    width: auto;
    display: flex;
    align-items:center ;
    justify-content: center;
    
  }

  /* .side-card{
    display: flex;   
   object-fit:cotain;
    } */


 