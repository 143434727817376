
* {
  font-family: Open Sans;
}
body {
  margin: 0;
  font-family:'IBM Plex Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.banner{
  text-align:center;
  height:Auto;
  color:#ffffff;
  font-size:27px;
  
}


.show{
  display:grid; 
  grid-template-rows:80% 20%;
  grid-template-columns:1fr 3fr;
}




@media screen and (max-width: 780px) {
  .banner{

    font-size:15px; 
  
  }

  .show{
    display:grid; 
    grid-template-rows:20% 50% 30%;
    grid-template-columns:1fr ;
  }


}



